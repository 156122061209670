<template>
  <PageContent class="devis">
    <template #header-right>
      <ButtonClassic
        :label="$t('filtre.filtres')"
        color="primary"
        :variant="showFilterSidebar ? 'solid' : 'ghost'"
        :class="{ active: showFilterSidebar, }"
        :disabled="isLoading"
        icon="right"
        @click="showFilterSidebar = !showFilterSidebar"
      >
        <template #right-icon>
          <UilFilter v-if="!showFilterSidebar" />
          <UilTimes v-else />
        </template>
      </ButtonClassic>
      <ButtonGroup>
        <ButtonClassic
          balise-type="button"
          variant="solid"
          icon="right"
          :disabled="!selections.length"
          @click="downloadPdf('devis')"
        >
          <template #right-icon>
            <IconPDF class="icon-medium" />
          </template>
        </ButtonClassic>
        <ButtonClassic
          variant="solid"
          icon="right"
          @click="copyToClipboard(`${currentUrl}?id_organisme=${organismeActuel.id}`)"
        >
          <template #right-icon>
            <UilShareAlt />
          </template>
        </ButtonClassic>
      </ButtonGroup>
      <ContextMenu
        :bloc-actions-globales="actionsGlobales"
        :bloc-actions-selections="actionsSelections"
        :selections="selections"
        @click-action="handleActions($event)"
      >
        <template
          v-if="isMaitreComptaRegion"
          #header
        >
          <ButtonToggle
            v-model="collapsedDevis"
            type="switch"
            :label="$t('context-menu.deployer-tous-les-etablissements')"
            name="deploiement"
          />
        </template>
      </ContextMenu>
    </template>

    <template
      v-if="!isLoading
        && !isMaitreComptaRegion
        && Object.keys(budget).length
        && budget.dotation > 0
      "
      #action-bar
    >
      <InfosDotation
        :budget="budget"
        :budget-type="budgetType"
      />
    </template>

    <template #aside-content>
      <FilterSidebar
        v-if="showFilterSidebar && allFilters.length"
        :title="$t('recherche.affiner-votre-recherche')"
        :possible-filters="allFilters"
        :active-filters="activeFilters"
        @update="fetch()"
      />
    </template>

    <template
      v-if="!isLoading"
      #content
    >
      <ErrorPage
        v-if="noResults"
        variant="no-results"
      />
      <template v-else-if="!emptyData">
        <DevisCommandesMaitreComptaTable
          v-if="isMaitreComptaRegion"
          :rows="rows"
          :active-sort="sort"
          :children-active-sort="sortChildren"
          :children-are-loading="subArrayIsLoading"
          :budget-type="budgetType"
          :sub-page-size="subPageSize"
          @click-refresh="$event._children ? refreshAll($event._children) : refresh($event)"
          @click-validate="$event._children ? validateAll($event._children) : validate($event)"
          @click-submit="$event._children ? submitAll($event._children) : submit($event)"
          @click-approve="$event._children ? approveAll($event._children) : approve($event)"
          @click-order="$event._children ? checkCgv($event._children) : checkCgv($event)"
          @sort="handleSort($event)"
          @sort-children="handleSortChildren($event)"
        />
        <DevisCommandesTable
          v-else
          :rows="rows"
          :active-sort="sort"
          @sort="handleSort($event)"
          @click-validate="validate($event)"
          @click-submit="submit($event)"
          @click-approve="approve($event)"
          @click-order="checkCgv($event)"
          @click-delete="deleteDevis($event)"
          @click-refresh="refresh($event)"
        />
        <div
          v-if="totalRows > pageSize"
          class="pagination-container"
        >
          <Pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="pageSize"
            @change="(page) => $router.push({ query: { ...$route.query, page, }, })"
          />
        </div>
        <ModalDevisAskReason
          type="rejet"
          @send="rejectAll($event)"
        />
      </template>
      <div
        v-else
        class="empty-page"
      >
        <h2 class="aucun s4 light">
          <template v-if="hasPerm('can_devis_to_cmd')">
            <template v-if="isRouteSoumis">
              {{ $t('devis.aucun-devis-a-approuver-ou-a-commander') }}
            </template>
            <template v-else>
              {{ $tc('devis.vos-ets-aucun-devis-en-cours', isHorsMarche ? 1 : 2) }}
            </template>
          </template>
          <template v-else-if="hasPerm('can_transmettre_devis')">
            {{ $t('devis.aucun-devis-a-valider') }}
          </template>
          <template v-else>
            {{ $t('devis.aucun-devis') }}
          </template>
          <template v-if="$route.query.etablissements">
            ({{ $t("filtre.avec-filtres-actuels") }})
          </template>
        </h2>
        <img
          :src="require('@lde/core_lde_vue/dist/assets/media/illus/illus_cart.svg')"
          :alt="$t('general.alt-image-illustration')"
        />
      </div>
      <ModalDevisCommande
        source-type="devis"
        destination-type="commande"
        :skip-bascule="isMaitreComptaRegion"
        :all-cgv="allCgv"
        @submit="checkOrder($event)"
      />
      <ModalDevisConfirmDeletion
        :to-delete="toDelete"
        @confirm="$event.length > 1 ? deleteAllDevis($event, true) : deleteDevis($event[0], true)"
      />
      <ModalDuplicateDevisCommande
        :doublon-infos="doublonInfos"
        :nom="promiseWait?.devis?.libelle"
        @force="handleDuplicate(true)"
        @hidden="handleDuplicate(false)"
      />
    </template>
    <template
      v-else
      #content
    >
      <div>
        <InfiniteLoader />
      </div>
    </template>
  </PageContent>
</template>

<script>
import { ButtonToggle, copyToClipboard } from "@lde/core_lde_vue";

import ModalDevisCommande from "@/components/modals/ModalDevisCommande.vue";
import ModalDevisAskReason from "@/components/modals/ModalDevisAskReason.vue";
import ModalDevisConfirmDeletion from "@/components/modals/ModalDevisConfirmDeletion.vue";
import ModalDuplicateDevisCommande from "@/components/modals/ModalDuplicateDevisCommande.vue";

import DevisCommandes from "@/mixins/mixinDevisCommandes";
import DownloadMultiPdf from "@/mixins/mixinDownloadMultiPdf";
import mixinDisponibilite from "@/mixins/mixinDisponibilite";
import SearchFilters from "@/mixins/mixinSearchFilters";
import DuplicateInfos from "@/mixins/mixinDuplicateModal";

import Api from "@/modules/axios";

import config from "@/config";

import {
  UilProcess,
  UilTimesCircle,
  UilCheckCircle,
  UilTrashAlt,
  UilFileExport,
} from "@iconscout/vue-unicons";

import IconPanierFleche from "@/components/icons/IconPanierFleche.vue";

/**
 * Vue de la liste des devis.
 */
export default {
  name: "Devis",
  components: {
    ButtonToggle,
    ModalDevisAskReason,
    ModalDevisCommande,
    ModalDevisConfirmDeletion,
    ModalDuplicateDevisCommande,
  },
  mixins: [DevisCommandes, DownloadMultiPdf, mixinDisponibilite, SearchFilters, DuplicateInfos],
  data() {
    return {
      currentUrl: window.location.href,
      devis: [],
      showFilterSidebar: false,
      filterTimeout: null,
      sort: {},
      allCgv: [],
      devisToOrder: [],
      toDelete: [],
      promiseWait: null,
    };
  },
  computed: {
    isMaitreComptaRegion() {
      return ["listes_devis_devis_soumis", "listes_devis_devis_non_soumis"].includes(this.$route.name);
    },
    actionsRegion() {
      let actions = [
        { slug: "refresh_all", label: this.$t("action.tout-renouveler"), icon: UilProcess },
      ];

      if (this.isRouteSoumis) {
        actions = [
          ...actions,
          { slug: "approve_all", label: this.$t("action.tout-approuver"), icon: UilCheckCircle },
          { slug: "order_all", label: this.$t("action.tout-commander"), icon: IconPanierFleche },
          { slug: "reject_all", label: this.$t("action.tout-rejeter"), icon: UilTimesCircle },
        ];
      } else {
        actions = [
          ...actions,
          { slug: "validate_all", label: this.$t("action.tout-valider"), icon: UilCheckCircle },
          { slug: "submit_all", label: this.$t("action.tout-soumettre"), icon: UilFileExport },
          { slug: "delete_all", label: this.$t("action.tout-supprimer"), icon: UilTrashAlt },
        ];
      }

      return actions;
    },
    actionsValideur() {
      let actions = [
        { slug: "refresh_all", label: this.$t("action.tout-renouveler"), icon: UilProcess },
      ];

      if (this.isHorsMarche) {
        actions.push({ slug: "order_all", label: this.$t("action.tout-commander"), icon: IconPanierFleche });
      } else {
        actions = [
          ...actions,
          { slug: "validate_all", label: this.$t("action.tout-valider"), icon: UilCheckCircle },
          { slug: "submit_all", label: this.$t("action.tout-soumettre"), icon: UilFileExport },
        ];
      }

      actions.push({ slug: "delete_all", label: this.$t("action.tout-supprimer"), icon: UilTrashAlt });

      return actions;
    },
    actionsSelections() {
      if (this.hasPerm("can_devis_to_cmd") && !this.isHorsMarche) {
        const actions = this.actionsRegion.map((action) => {
          action.disabled = this.isImpersonating || !this.selections.length || this.selections.some(
            (devis) => devis.verrouille,
          );
          return action;
        });
        return actions;
      }
      if (this.hasPerm("can_transmettre_devis")) {
        return this.actionsValideur;
      }
      return [];
    },
    selections() {
      if (this.isMaitreComptaRegion) {
        let checkedDevis = [];
        this.rows.forEach((org) => {
          checkedDevis = [...checkedDevis, ...org._children.filter((devis) => devis._checked)];
        });
        return checkedDevis;
      }
      return this.rows.filter((d) => d._checked);
    },
    collapsedDevis: {
      get() {
        return this.rows.every((ligne) => ligne._expanded);
      },
      set(newVal) {
        this.collapseElements(newVal);
      },
    },
    isRouteSoumis() {
      return this.$route.name === "listes_devis_devis_soumis";
    },
    organismesDevisToOrder() {
      const idsOrganismes = this.devisToOrder.map((devis) => devis.id_organisme);
      return this.rows.filter((org) => idsOrganismes.includes(org.id_organisme));
    },
  },
  mounted() {
    this.fetchBudget(this.isMaitreComptaRegion)
      .finally(() => {
        this.fetch();
      });
  },
  methods: {
    copyToClipboard,
    /**
     * Callback des actions du menu contextuel (tout approuver, tout supprimer…).
     * @param {Object} action Objet definissant l'action avec son slug, son label…
     */
    handleActions(action) {
      this.$matomo.trackEvent(
        action.slug,
        "click",
        `Sélection: ${this.selections.length}`,
      );
      switch (action.slug) {
        case "validate_all":
          // Valider les devis
          this.changeMultipleStatus(
            { se_etat: this.isHorsMarche ? config.statutsCommande.VALIDE : config.statutsCommande.VALIDE_DIRECTEUR },
            ["attente_validation"],
            this.$t("devis.tous-devis-pas-mesure-valides"),
            this.$t("devis.bien-ete-valide"),
          );
          break;
        case "submit_all":
          // Soumettre les devis
          this.changeMultipleStatus(
            { se_etat: config.statutsCommande.TRANSMIS },
            ["attente_validation", "attente_soumission"],
            this.$t("devis.tous-devis-pas-mesure-soumis"),
            this.$t("devis.bien-ete-valide"),
          );
          break;
        case "approve_all":
          // Approuver les devis
          this.changeMultipleStatus(
            { se_etat: config.statutsCommande.VALIDE },
            ["attente_approbation"],
            this.$t("devis.tous-devis-pas-mesure-approuves"),
            this.$t("devis.vos-devis-approuves"),
          );
          break;
        case "order_all":
          // Commander les devis
          this.checkCgv(this.selections);
          break;
        case "reject_all":
          // Rejeter les devis
          this.$modal.show("modal_devis_ask_reason");
          break;
        case "delete_all":
          // Supprimer les devis
          this.deleteAllDevis(this.selections);
          break;
        case "refresh_all":
          // Actualise les devis
          this.refreshAll(this.selections);
          break;
        case "ask_export_xls":
          this.$store.dispatch("askExportStatsMarche");
          break;
        case "export_xls":
          this.exportXls("devis");
          break;
        default:
          break;
      }
    },
    /**
     * Modifie plusieurs devis (dans this.selections) avec les données souhaitées.
     * @param {Object} data Contient les données à patch sur le devis.
     * @param {Array} statuts Contient les statuts qui sont valides pour modifier le statut du devis.
     * @param {String} warningMsg Message affiché dans le toast lorsque tous les devis n'ont pas le bon statut.
     * @param {String} successMsg Message affiché dans le toast lorsque la fonction est concluante.
     */
    async changeMultipleStatus(data, statuts, warningMsg, successMsg) {
      // On récupère le statut du devis qui correspond au se_etat pour filtrer dessus
      const toChange = this.selections.filter((element) => statuts.includes(element.statut_affiche));

      // Si tous les devis n'ont pas le bon statut pour être modifiés dans le nouveau statut, on prévient l'utilisateur
      if (toChange.length !== this.selections.length) {
        this.$toast.warning({ title: warningMsg });
      }

      if (toChange.length) {
        let count = 0;
        this.$toast.info({
          title: this.$t("toast.envoi-en-cours"),
          content: this.$t("toast.veuillez-patienter"),
        });
        this.isLoading = true;
        // TODO: Modifier une fois qu'on aura une seule requête pour de multiple devis
        for (let i = 0; i < toChange.length; i += 1) {
          console.log("toChange[i]", toChange[i]);
          await this.patchDevis(toChange[i], data) // eslint-disable-line no-await-in-loop
            .then(() => { // eslint-disable-line no-loop-func
              count += 1;
              this.$set(toChange[i], "_checked", false);
              // Si on est à la fin
              if (count === toChange.length) {
                this.$toast.success({
                  title: this.$t("toast.modifications-bien-prise-en-compte"),
                  content: successMsg,
                });
                this.fetch();
              }
            });
        }
      }
    },
    /**
     * Rejete les devis seléctionnés.
     * @param {String} text Raison du rejet.
     */
    rejectAll(text) {
      this.changeMultipleStatus(
        { se_etat: config.statutsCommande.REFUSE, se_raison_refus: text },
        ["attente_approbation", "attente_commande"],
        this.$t("devis.tous-devis-pas-mesure-rejetes"),
        this.$t("devis.vos-devis-bien-rejetes"),
      ).then(() => {
        this.$modal.hide("modal_devis_ask_reason");
      });
    },
    /**
     * Supprime un devis.
     * @param {Object} devis Infos du devis.
     */
    deleteDevis(devis, confirm) {
      if (!confirm) {
        this.toDelete = [devis];
        return this.$modal.show("modal_devis_confirm_deletion");
      }

      if (!this.isImpersonating && ["attente_validation", "refus", "expire"].includes(devis.statut_affiche)) {
        this.$matomo.trackEvent(
          "delete_devis",
          "click",
        );
        this.isLoading = true;
        this.patchDevis(devis, { date_archivage: new Date() })
          .then(() => {
            this.$toast.success({
              title: this.$t("devis.modification-bien-prise-en-compte"),
              content: this.$t("devis.devis-x-bien-supprime", { libelle: devis.libelle }),
            });
            this.fetch();
          });
      }
      return true;
    },
    /**
     * Supprime tous les devis sélectionnés.
     * @param {Array} selections Tableau des devis sélectionnés.
     */
    deleteAllDevis(selections, confirm) {
      if (!confirm) {
        this.toDelete = selections;
        return this.$modal.show("modal_devis_confirm_deletion");
      }

      this.selections = selections;

      this.changeMultipleStatus(
        { date_archivage: new Date() },
        ["refus", "attente_validation", "expire"],
        this.$t("devis.tous-devis-pas-mesure-supprimes"),
        this.$t("devis.bien-ete-supprime"),
      );
      return true;
    },
    /**
     * Valide un devis.
     * @param {Object} devis Infos du devis.
     */
    validate(devis) {
      if (!this.isImpersonating) {
        const seEtat = this.isHorsMarche ? config.statutsCommande.VALIDE : config.statutsCommande.VALIDE_DIRECTEUR;
        if (devis.statut_affiche === "attente_validation") {
          this.$toast.info({
            title: this.$t("toast.envoi-en-cours"),
            content: this.$t("toast.veuillez-patienter"),
          });
          this.$matomo.trackEvent(
            "validate_devis",
            "click",
          );
          this.isLoading = true;
          this.patchDevis(devis, { se_etat: seEtat }).then(() => {
            this.$toast.success({
              title: this.$t("devis.modification-bien-prise-en-compte"),
              content: this.$t("devis.x-a-bien-ete-valide", { libelle: devis.libelle }),
            });
            this.fetch();
          });
        } else {
          this.$toast.error({ title: this.$t("devis.votre-devis-pas-mesure-valide") });
        }
      }
    },
    /**
     * Valide tous les devis sélectionnés.
     * @param {Array} selections Tableau des devis sélectionnés.
     */
    validateAll(selections) {
      if (!this.isImpersonating) {
        const toValidate = selections.filter((element) => element.statut_affiche === "attente_validation");

        if (toValidate.length !== selections.length) {
          this.$toast.warning({ title: this.$t("devis.tous-devis-pas-mesure-valides") });
        }

        if (toValidate.length) {
          let count = 0;
          this.$toast.info({
            title: this.$t("toast.envoi-en-cours"),
            content: this.$t("toast.veuillez-patienter"),
          });
          this.isLoading = true;
          // TODO: Modifier une fois qu'on aura une seule requête pour multiple devis.
          toValidate.forEach((ligne) => {
            this.patchDevis(ligne, { se_etat: config.statutsCommande.VALIDE_DIRECTEUR }).then(() => {
              count += 1;
              // Si on est à la fin
              if (count === toValidate.length) {
                this.$toast.success({
                  title: this.$t("toast.modifications-bien-prise-en-compte"),
                  content: this.$t("toast.devis-bien-valides"),
                });
                this.fetch();
              }
            });
          });
        }
      }
    },
    /**
     * Soumet un devis.
     * @param {Object} devis Infos du devis.
     */
    submit(devis) {
      if (!this.isImpersonating) {
        const seEtat = config.statutsCommande.TRANSMIS;
        if (["attente_validation", "attente_soumission"].includes(devis.statut_affiche)) {
          this.$toast.info({
            title: this.$t("toast.envoi-en-cours"),
            content: this.$t("toast.veuillez-patienter"),
          });
          this.$matomo.trackEvent(
            "approve_devis",
            "click",
          );
          this.isLoading = true;
          this.patchDevis(devis, { se_etat: seEtat }).then(() => {
            this.$toast.success({
              title: this.$t("devis.modification-bien-prise-en-compte"),
              content: this.$t("devis.x-a-bien-ete-soumis", { libelle: devis.libelle }),
            });
            this.fetch();
          });
        } else {
          this.$toast.error({ title: this.$t("devis.devis-pas-mesure-soumis") });
        }
      }
    },
    /**
     * Soumet tous les devis sélectionnés.
     * @param {Array} selections Tableau des devis sélectionnés.
     */
    submitAll(selections) {
      if (!this.isImpersonating) {
        const toSumbit = selections.filter((element) => (
          ["attente_validation", "attente_soumission"].includes(element.statut_affiche)));

        if (toSumbit.length !== selections.length) {
          this.$toast.warning({ title: this.$t("devis.tous-devis-pas-mesure-soumis") });
        }

        if (toSumbit.length) {
          let count = 0;
          this.$toast.info({
            title: this.$t("toast.envoi-en-cours"),
            content: this.$t("toast.veuillez-patienter"),
          });
          this.isLoading = true;
          // TODO: Modifier une fois qu'on aura une seule requête pour multiple devis.
          toSumbit.forEach((ligne) => {
            this.patchDevis(ligne, { se_etat: config.statutsCommande.TRANSMIS }).then(() => {
              count += 1;
              // Si on est à la fin
              if (count === toSumbit.length) {
                this.$toast.success({
                  title: this.$t("toast.modifications-bien-prise-en-compte"),
                  content: this.$t("toast.devis-bien-soumis"),
                });
                this.fetch();
              }
            });
          });
        }
      }
    },
    /**
     * Approuve un devis.
     * @param {Object} devis Infos du devis.
     */
    approve(devis) {
      if (!this.isImpersonating) {
        const seEtat = config.statutsCommande.VALIDE;
        if (devis.statut_affiche === "attente_approbation") {
          this.$toast.info({
            title: this.$t("toast.envoi-en-cours"),
            content: this.$t("toast.veuillez-patienter"),
          });
          this.$matomo.trackEvent(
            "approve_devis",
            "click",
          );
          this.isLoading = true;
          this.patchDevis(devis, { se_etat: seEtat }).then(() => {
            this.$toast.success({
              title: this.$t("devis.modification-bien-prise-en-compte"),
              content: this.$t("devis.x-a-bien-ete-approuve", { libelle: devis.libelle }),
            });
            this.fetch();
          });
        } else {
          this.$toast.error({ title: this.$t("devis.devis-pas-mesure-approuve") });
        }
      }
    },
    /**
     * Approuve tous les devis sélectionnés.
     * @param {Array} selections Tableau des devis sélectionnés.
     */
    approveAll(selections) {
      if (!this.isImpersonating) {
        const toApprove = selections.filter((element) => element.statut_affiche === "attente_approbation");

        if (toApprove.length !== selections.length) {
          this.$toast.warning({ title: this.$t("devis.tous-devis-pas-mesure-approuves") });
        }

        if (toApprove.length) {
          let count = 0;
          this.$toast.info({
            title: this.$t("toast.envoi-en-cours"),
            content: this.$t("toast.veuillez-patienter"),
          });
          this.isLoading = true;
          // TODO: Modifier une fois qu'on aura une seule requête pour multiple devis.
          toApprove.forEach((ligne) => {
            this.patchDevis(ligne, { se_etat: config.statutsCommande.VALIDE }).then(() => {
              count += 1;
              // Si on est à la fin
              if (count === toApprove.length) {
                this.$toast.success({
                  title: this.$t("toast.modifications-bien-prise-en-compte"),
                  content: this.$t("toast.devis-bien-approuves"),
                });
                this.fetch();
              }
            });
          });
        }
      }
    },
    /**
     * Récupère les liens des CGV des distributeurs des produits d'un devis.
     * @param {Array} devisId ID du devis.
     * @returns {Promise} Distributeurs liés à la ligne.
     */
    fetchCgvUrl(devisId) {
      return Api().get(`/devis/${devisId}/cgv_check/`)
        .then(({ data: { distributeurs } }) => {
          // Les distributeurs sont renvoyés sous la forme [[nom1, url1], [nom2, url2], ...]
          distributeurs.forEach((distri) => {
            // On vérifie que le distributeur n'est pas déjà dans le tableau pour éviter les doublons
            if (!this.allCgv.some((cgv) => distri[0].indexOf(cgv[0]) >= 0)) {
              this.allCgv.push(distri);
            }
          });
        });
    },
    /**
     * Vérifie si on affiche la modale des CGV.
     * @param {Array} selections Devis à passer en commande.
     */
    checkCgv(selections) {
      this.devisToOrder = Array.isArray(selections) ? selections : [selections];

      if (
        !this.isHorsMarche
        && this.devisToOrder.some((devis) => devis.id_organisme !== this.devisToOrder[0].id_organisme)
        && this.organismeActuel.infos.chorus_configuration.chorus === 1
      ) {
        // On doit sélectionner uniquement des devis d'un même établissement pour un passage en commande groupé
        this.$toast.error({ title: this.$tc("devis.devis-selectionnes-doivent-appartenir-meme-ets") });
      } else {
        // On met un chargement car récupérer les CGV peut prendre du temps selon le nombre de devis sélectionnés
        this.isLoading = true;
        this.allCgv = [];

        const promises = this.devisToOrder.map((devis) => this.fetchCgvUrl(devis.id));
        Promise.all(promises)
          .then(() => {
            this.isLoading = false;
            this.$nextTick(() => {
              this.$modal.show("modal_devis_commande");
            });
          });
      }
    },
    /**
     * Détermine quelle méthode de commande appliquer et rajoute les paramètres Chorus.
     * @param {Object} body Paramètres à envoyer à l'API lors du passage en commande (num engagement).
     */
    checkOrder(body = {}) {
      if (this.devisToOrder.length > 1) {
        this.orderAll(this.devisToOrder, body);
      } else {
        this.order(this.devisToOrder[0], body);
      }
    },
    /**
     * Passe un devis en commande.
     * @param {Object} ligne Contient les données du devis à commander.
     * @param {Object} body Paramètres à envoyer à l'API lors du passage en commande.
     */
    order(ligne, body = {}, force) {
      if (!this.isImpersonating) {
        const states = this.isHorsMarche
          ? ["attente_validation", "attente_commande"]
          : ["attente_approbation", "attente_commande"];

        if (states.includes(ligne.statut_affiche)) {
          this.$toast.info({
            title: this.$t("toast.envoi-en-cours"),
            content: this.$t("toast.veuillez-patienter"),
          });
          this.$matomo.trackEvent(
            "order_devis",
            "click",
          );
          this.isLoading = true;

          return Api().post(`/devis/${ligne.id}/to_commande/`, { ...body, force })
            .then(() => {
              this.$toast.success({
                title: this.$tc("commande.commande-validee", 1),
                content: this.$t("commande.devis-passe-en-commande"),
              });

              if (this.promiseWait?.fetch) {
                this.fetch();
              }
            })
            .catch((err) => {
              this.isLoading = false;
              if (err.response) {
                if (!this.handleDuplicateModalToOpen(err.response.data)) {
                  this.$toast.error({
                    title: (
                    err.response.data?.detail
                    || err.response.data
                    || this.$t("info.une-erreur-est-survenue")
                    ),
                  });
                } else {
                  return new Promise((resolve, reject) => {
                    this.promiseWait = {
                      action: "order", resolve, reject, devis: ligne, body, fetch: true,
                    };
                  });
                }
              }
              return true;
            });
        }

        this.$toast.error({ title: this.$t("devis.votre-devis-pas-mesure-commande") });
        return false;
      }
      return false;
    },
    async handleDuplicate(validated) {
      if (validated) {
        if (this.promiseWait.action === "order") {
          await this.order(this.promiseWait.devis, this.promiseWait.body, true);
        } else if (this.promiseWait.action === "patch") {
          await this.patchDevis(this.promiseWait.devis, this.promiseWait.body, true);
        }
      }

      this.promiseWait.resolve();
    },
    /**
     * Commande tous les devis sélectionnés.
     * @param {Array} selections Tableau des devis sélectionnés.
     * @param {Object} body Paramètres à envoyer à l'API lors du passage en commande.
     */
    async orderAll(selections, body = {}) {
      if (!this.isImpersonating) {
        let count = 0;
        const states = this.isHorsMarche
          ? ["attente_validation", "attente_commande"]
          : ["attente_approbation", "attente_commande"];
        const toOrder = selections.filter((el) => states.includes(el.statut_affiche));

        if (toOrder.length !== selections.length) {
          this.$toast.warning({ title: this.$t("devis.tous-devis-pas-mesure-commandes") });
          this.isLoading = false;
          this.$modal.hide("modal_devis_commande");
        } else if (toOrder.length) {
          this.$toast.info({
            title: this.$t("toast.envoi-en-cours"),
            content: this.$t("toast.veuillez-patienter"),
          });

          for (let i = 0; i < toOrder.length; i += 1) {
            this.isLoading = true;
            await Api().post(`/devis/${toOrder[i].id}/to_commande/`, body) // eslint-disable-line no-await-in-loop
              .then(() => { // eslint-disable-line no-loop-func
                count += 1;
                this.$set(toOrder[i], "_checked", false);
                if (count === toOrder.length) {
                  this.$toast.success({
                    title: this.$tc("commande.commande-validee", count),
                    content: this.$t("devis.vos-devis-bien-commandes"),
                  });
                }
              })
              .catch((err) => {
                this.isLoading = false;
                this.statusBtnDisabled = false;
                if (err.response) {
                  if (!this.handleDuplicateModalToOpen(err.response.data)) {
                    this.$toast.error({
                      title:
                        err.response.data || this.$t("info.une-erreur-est-survenue"),
                    });
                  } else {
                    return new Promise((resolve, reject) => {
                      this.promiseWait = {
                        action: "order", resolve, reject, devis: toOrder[i], body, fetch: i === (toOrder.length - 1),
                      };
                    });
                  }
                }

                return true;
              });
          }

          this.fetch();
        }
      }
    },
    /**
     * Renouvelle tous les devis sélectionnés.
     * @param {Object} selections Tableau des devis sélectionnés.
     */
    refreshAll(selections) {
      if (!this.isImpersonating) {
        let count = 0;
        const toRefresh = selections.filter((el) => el.statut_affiche === "expire");

        if (toRefresh.length !== selections.length) {
          this.$toast.warning({ title: this.$t("devis.tous-devis-pas-mesure-renouveles") });
        }

        if (toRefresh.length) {
          this.$toast.info({
            title: this.$t("toast.envoi-en-cours"),
            content: this.$t("toast.veuillez-patienter"),
          });
          this.isLoading = true;
          // TODO: Modifier une fois qu'on aura une seule requête pour multiple devis.
          toRefresh.forEach((devis) => {
            Api().post(`/devis/${devis.id}/refresh/`).then(() => {
              count += 1;
              this.$set(devis, "_checked", false);
              if (count === toRefresh.length) {
                this.$toast.success({ title: this.$t("devis.vos-devis-bien-renouveles") });
                this.fetch();
              }
            }).catch((err) => {
              this.$toast.error({ title: err.response.data.detail });
            });
          });
        }
      }
    },
    /**
     * Renouvelle un devis.
     * @param {Object} devis Infos du devis.
     */
    refresh(devis) {
      if (!this.isImpersonating) {
        this.isLoading = true;
        Api().post(`devis/${devis.id}/refresh/`)
          .then(() => {
            this.$toast.success({ title: this.$t("devis.votre-devis-a-bien-ete-renouvele") });
            this.fetch();
          }).catch((err) => {
            this.$toast.error({ title: err.response?.data || err.message });
          });
      }
    },
    /**
     * Fait un appel à l'api pour modifier l'état du devis.
     * @param {Object} devis Devis à modifier.
     * @param {Object} data Données à modifier pour ce devis.
     * @returns {Promise} Devis modifié.
     */
    patchDevis(devis, data, force) {
      return new Promise((resolve, reject) => {
        this.isLoading = true;
        Api().patch(`/devis/${devis.id}/`, { ...data, force })
          .then(() => { resolve(); })
          .catch((err) => {
            this.isLoading = false;
            console.log(err.response.data);
            // Une 404 mais c'est normal, car le devis devient inaccessible
            if (err.response && err.response.status === 404) {
              this.$store.commit("setErrorStatus", null);
              resolve();
            } else if (err.response && err.response.status === 403) {
              this.$toast.error({ title: err.response.data.detail });
              reject(err);
            } else if (err.response && err.response.status === 400) {
              if (err.response.data.code === "DOTATION_DEPASSEE") {
                this.$toast.error({
                  title: this.$t("toast.transmission-impossible"),
                  content: err.response.data.message,
                });
              } else if (!this.handleDuplicateModalToOpen(err.response.data)) {
                this.$toast.error({
                  title:
                      err.response.data || this.$t("info.une-erreur-est-survenue"),
                });
              } else {
                const promise = new Promise((pResolve, pReject) => {
                  this.promiseWait = {
                    action: "patch", resolve: pResolve, reject: pReject, devis, body: data,
                  };
                });
                resolve(promise);
              }
              reject(err);
            } else {
              this.$toast.error({
                title: err.response?.data?.message || "",
              });
              reject(err);
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },
    /**
     * Appelle la bonne méthode de récupération des devis en fonction des permissions (région ou non).
     * @returns {Promise}
     */
    fetch() {
      this.showFilterSidebar = false;
      return this.isMaitreComptaRegion ? this.fetchDevisRegion() : this.fetchDevisEtab();
    },
    /**
     * Récupère les devis de l'établissement courant (prescripteur et valideur).
     */
    fetchDevisEtab() {
      this.isLoading = true;

      const filterQuery = this.activeFilters.map(({ slug, options }) => [`${slug}__in`, options.join(",")]);
      const params = {
        page_size: this.pageSize,
        page: this.currentPage,
        ...Object.fromEntries(filterQuery),
      };

      if (this.sort.key) {
        params.ordering = `${this.sort.sortAscended ? "" : "-"}${this.sort.key}`;
      }

      Api().get("/devis/", { params })
        .then(({ data: { results, count, filtres } }) => {
          this.allFilters = filtres;
          this.rows = results.map((devis) => ({
            id: devis.id,
            libelle: devis.libelle,
            id_4d: devis.id_4d,
            verrouille: devis.verrouille,
            date: devis.date,
            total_quantite: devis.total_quantite,
            statut_affiche: devis.statut_affiche,
            user_from_demande_role: devis.user_from_demande_modification_verbose?.role,
            total_ttc: devis.total_ttc,
            total_ht: devis.total_ht,
            url_pdf: devis.url_pdf,
            has_offres_indispos: devis.has_offres_indispos,
            _routeName: "listes_devis_devis_item",
            _variant: devis.statut_affiche === "expire" ? "dashed" : undefined,
          }));
          this.totalRows = count;
          this.isLoading = false;
        })
        .catch((err) => {
          this.$toast.error({
            title: err.response
              ? (err.response.data.detail
                || (Array.isArray(err.response.data) && err.response.data[0]))
                || this.$t("info.une-erreur-est-survenue")
              : this.$t("info.une-erreur-est-survenue"),
          });
        });
    },
    /**
     * Récupère les établissements (avec devis) ayant des devis à valider (donneur d'ordres/région).
     */
    fetchDevisRegion() {
      this.isLoading = true;

      const filterQuery = this.activeFilters.map(({ slug, options }) => [`${slug}__in`, options.join(",")]);
      const params = {
        page_size: this.pageSize,
        page: this.currentPage,
        ...Object.fromEntries(filterQuery),
        soumis: this.isRouteSoumis,
      };

      if (this.sort.key) {
        params.ordering = `${this.sort.sortAscended ? "" : "-"}${this.sort.key}`;
      }

      const { budgetsOrganismes } = this.budget;

      Api().get("/organisme/maitre_compta_devis/", { params })
        .then(({ data: { results: organismes, filtres = [], count: totalRows } }) => {
          this.totalRows = totalRows;
          this.noResults = filterQuery.length && !organismes.length;
          this.allFilters = filtres;
          if (organismes.length) {
            this.rows = organismes.map((org, idx) => {
              const devis = org.devis.results;
              const budget = budgetsOrganismes && budgetsOrganismes[org.id_organisme.toUpperCase()];

              return {
                tmp_count: idx,
                id: org.id,
                libelle: org.nom_complet,
                id_organisme: org.id_organisme,
                uai: org.uai,
                budget: budget ? this.formalizeBudget(budget) : {},
                total_ttc: org.total_ttc.toFixed(2),
                total_ht: org.total_ht.toFixed(2),
                counter: org.devis.count,
                total_quantite: org.total_quantite,
                currentPage: 1,
                isLoading: false,
                _children: devis.map((d) => ({
                  id: d.id,
                  libelle: d.libelle,
                  id_4d: d.id_4d,
                  date: d.date,
                  total_quantite: d.total_quantite,
                  statut_affiche: d.statut_affiche,
                  user_from_demande_role: d.user_from_demande_modification_verbose?.role,
                  verrouille: d.verrouille,
                  total_ttc: d.total_ttc,
                  total_ht: d.total_ht,
                  url_pdf: d.url_pdf,
                  id_organisme: org.id_organisme,
                  has_offres_indispos: d.has_offres_indispos,
                  _routeName: "listes_devis_devis_item",
                  _variant: d.statut_affiche === "expire" ? "dashed" : undefined,
                  _buttonAddCheckDisabled: d.statut_affiche !== "attente_approbation",
                  _buttonShoppingDisabled: !this.isAllowed("commander", d),
                })),
              };
            });
          }
          this.isLoading = false;
        });
    },
    /**
     * Récupère les commandes d'un établissement.
     * @param {Number} idOrganisme ID de l'organisme.
     * @param {Object} sort Clé et sens de tri.
     * @param {Number} page Numéro de page à afficher.
     * @returns {Promise} Devis d'un organisme selon la page et le tri.
     */
    fetchChildren(idOrganisme, sort, page) {
      const filterQuery = Object.fromEntries(
        this.activeFilters.map(({ slug, options }) => [`${slug}__in`, options.join(",")]),
      );

      const expire = filterQuery?.validite_devis__in;

      const params = {
        page_size: this.subPageSize,
        page,
        organisme: idOrganisme,
      };

      if (expire) {
        params.expire = expire;
      }

      if (this.isRouteSoumis) {
        params.soumis = this.isRouteSoumis;
      }

      if (sort) {
        this.sortChildren.key = sort.key;
        this.sortChildren.sortAscended = sort.sortAscended;
      }

      if (this.sortChildren?.key) {
        params.ordering = `${this.sortChildren.sortAscended ? "" : "-"}${this.sortChildren.key}`;
      }

      return Api().get("/devis/", { params })
        .then((res) => res.data.results.map(
          (devis) => ({
            id: devis.id,
            libelle: devis.libelle,
            id_4d: devis.id_4d,
            date: devis.date,
            quantite: devis.total_quantite,
            statut_affiche: devis.statut_affiche,
            user_from_demande_role: devis.user_from_demande_modification_verbose?.role,
            total_ttc: devis.total_ttc,
            total_ht: devis.total_ht,
            url_pdf: devis.url_pdf,
          }),
        ));
    },
  },
};
</script>

<style lang="scss"></style>
